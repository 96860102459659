@import url('https://fonts.googleapis.com/css?family=Montserrat:400,500,600,700|Poppins:300,400');

@body-font: 'Poppins', sans-serif;
@menu-font: @body-font;

@heading-font:'Montserrat', sans-serif;
@heading-font-alt:'Montserrat', sans-serif;
@slider-font: @heading-font;

@section-bg-light: #F8F9FA;
@primary-color: #EF3D32; //#FE3C47; #98cb2b
 
@top-bar-bg: rgba(254,60,71,1);
@top-bar-bg-transparent: rgba(254,60,71,0.9);
@top-bar-text: #FFFFFF;
@text-color: #333333;
@light-text-color: #FFFFFF;
@dark-text-color: #1A1A1A;
@default-font-color: #333333; //body font
@pure-white-bg: #FFFFFF;
@pure-black-bg: #000000;

@primary-bg-color: #FFFFFF; 
@second-bg-color: #F9FAFB;
@border-color: #EEE;
@dark-bg: #2C2C2C;
@light-bg: #FAFAFA;
@blog-bg-color: #F9FAFB;



@link-color: #777777;
@link-hover-color: #66CCFF;
@dark-color: #000000;

@gray-color: #EEEEEE;
@light-color: #F8F8F8;

@top-menu-background: #FFFFFF;
@main-menu-background: #FFFFFF;
@button-background: #399FD2;
@blockquote-background: #f8f8f8;
@separator-border: #808080;

@background-color: #FFFFFF;
@sidebar-bg: #FFFFFF;
@sub-text-color: #555555;


@secondary-color: #4E4E4E;
@light-border-color: #CCCCCC;
@dark-border-color: #212121;
@primary-btn-bg: @primary-color;
@secondary-btn-bg: @secondary-color;

@footer-bg: #1a1a1a;
@footer-sidebar-bg: #333333;
@footer-text-color: #C2C2C2;
@footer-sidebar-border: #444444;

@top-menu-bg: @footer-sidebar-bg;
@main-menu-bg: @footer-sidebar-bg;


@heading-font-color: #555555;
@header-background-color: rgba(256,256,256,1);
@anchor-color: #272727;